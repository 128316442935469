import React, { Component } from 'react';

class TermsAndConditions extends Component {
  render() {
    return (
      <>
      <div
  data-elementor-type="wp-page"
  data-elementor-id={265}
  className="elementor elementor-265"
  data-elementor-post-type="page"
>
  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-1d86a52 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="1d86a52"
    data-element_type="section"
  >
    <div className="elementor-background-overlay" />
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0a43a0f"
        data-id="0a43a0f"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-648216e elementor-widget elementor-widget-heading"
            data-id="648216e"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Terms &amp; Conditions
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-0c90c8c elementor-hidden-tablet elementor-widget elementor-widget-text-editor"
            data-id="0c90c8c"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                Please read these carefully as the person making this booking
                (either for him selves or for any other passenger) accepts all
                the below terms and conditions of UK Travel Route.
              </p>
              <h3>
                Deposits &amp; tickets are neither refundable nor changeable
                (terms &amp; conditions may apply).
              </h3>
              <p>
                Unless Specified, All the deposits paid and tickets purchased /
                issued are non refundable in case of cancellation or no show
                (Failure to arrive at departure airport on time) and non
                changeable before or after departure (date change is not
                permitted). Once flights reserved, bookings / tickets are non
                transferable to any other person means that name changes are not
                permitted. Issued Tickets are also not re-routable.
              </p>
              <p>
                If you are reserving the flight by making the advance partial
                payment (Initial deposit) then please note that fare/taxes may
                increase at any time without the prior notice. Its means the
                price is not guaranteed unless ticket is issued because airline
                / consolidator has right to increase the price due to any
                reason. We always recommend you to pay ASAP
                and get issue your ticket to avoid this situation. Further more
                if you will cancel your reservation due to any reason, then the
                paid deposit(s) will not be refunded.
              </p>
              <p>
                Regardless of any reason, 75GBP per person will be charged if
                you will cancel your reservation before ticket issuance. After
                issuance all payments are non-refundable.
                Full Initial Deposit is Refundable for some reason such as Blood Relative Death,Severe Sickness which leads the passenger to not medically cleared for travelling,(Medical report require)Flights cancelled due to weather issue or any sudden disaster.
Issued tickets can be Changeable/Refundable according to fare rules or Airline advisory.
However,The first date change has been free of cost before issuance(No date changes penalty).
              </p>
              <h3>Checking all fligth detials &amp; passenger name(s)</h3>
              <p>
                It is your responsibility to check all the details are correct
                i.e. Passenger names (are same as appearing on passport / travel
                docs), Travelling dates, Transit Time, Origin &amp; Destination,
                Stop Over, Baggage Allowance and other flight information. Once
                the ticket is issued then no changes can be made.
              </p>
              <h3>Passport, Visa &amp; Immigration requirements</h3>
              <p>
                You are responsible for checking all these items like Passport,
                Visa (including Transit Visa) and other immigration
                requirements. You must consult with the relevant Embassy /
                Consulate, well before the departure time for the up to date
                information as requirements may change time to time. We can not
                accept any liability of any transit visa and if you are refused
                the entry onto the flight or into any country due to failure on
                your part to carry the correct passport, visa or other documents
                required by any airline, authority or country.
              </p>
              <h3>Reconfirming return/onward flights</h3>
              <p>
                It is your responsibility to RECONFIRM your flights at least 72
                hours before your departure time either with your travel agent
                or the relevant Airline directly. The company will not be liable
                for any additional costs due to your failure to reconfirm your
                flight and airline failure to operate.
              </p>
              <h3>Insurance and baggage loss</h3>
              <p>
                We recommend that you purchase travel insurance. It is your
                responsibility to ensure you have valid travel insurance that
                covers your needs and also ensure that you have complied with
                all the health and vaccination requirements for the countries
                you are travelling Advice can be obtained from your GP or travel
                clinic. We don’t accept any claim for the lost / Stolen /
                Damaged Baggage. You have to contact the relevant airline
                directly in that case.
              </p>
              <h3>Special requests and medical problems</h3>
              <p>
                If you have any special requests like meal preference, Seat
                Allocation and wheel chair request etc, please advise us at time
                of issuance of ticket. We will try our best to fulfill these by
                passing this request to relevant airline but we cannot guarantee
                and failure to meet any special request will not held us liable
                for any claim.
              </p>
              <h3>Very important</h3>
              <p>
                UK Travel Route. do not accept responsibility for any financial
                loss if the airline fails to operate. Passengers will be solely
                responsible for that so it is highly recommended that separate
                travel insurance must be arranged to protect yourself.
              </p>
              <div className="col-xs-12 clear-padding">
                <h3>Booking Terms and Conditions in Details</h3>
                <p>
                  When making a purchase/reservation you guarantee that you have
                  the authority to accept and do accept on behalf of your
                  party/dependant the terms of these booking conditions and the
                  booking conditions of any contract principal where we are
                  acting as agent. Therefore, it is requested please take some
                  time to go through the terms and conditions as stated here and
                  applicable to all contracts/purchase with us.
                </p>
                <p>
                  These conditions apply to all bookings – please take the time
                  to read and understand them OR contact our personnel to
                  discuss or answer your questions. The conditions are split
                  into 3 parts.
                </p>
                <p>
                  <b>Part 1</b>&nbsp;applies to all bookings (holiday, package,
                  flight only, hotel only, car hire, insurance, any/all
                  purchases
                </p>
                <p>
                  <b>Part 2</b>&nbsp;also aplies to all bookings classified as
                  “Packages” by the terms in “Your Holiday Contract” and
                </p>
                <p>
                  <b>Part 3</b>&nbsp;applies to bookings classified as “Other
                  Travel Arrangements” also subjected to other conditions as
                  stated above.
                </p>
                <p>
                  Please note the following are the company policy and terms
                  &amp; booking conditions while, in certain cases a supplier,
                  holiday provider, accommodation provider, hotelier,
                  international air control or airline policy terms may also be
                  applicable as / when advised.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

      </>
    )
  }
}

export default TermsAndConditions;
