import React, { Component } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/CustomerDetails.css';

function withNavigate(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}
class CustomerDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingSuccess: false,
      customerDetails: {
        name: '',
        email: localStorage.getItem("email") || "", // Load email from localStorage
        phone: localStorage.getItem("phone") || "", // Load phone from localStorage
      },
    };
  }

  handleChange = (e) => {
   // const { name, value } = e.target;
    // this.setState((prevState) => ({
    //   customerDetails: {
    //     ...prevState.customerDetails,
    //     [name]: value,
    //   },
    // }));

    const { name, value } = e.target;

    this.setState((prevState) => {
      const updatedDetails = { ...prevState.customerDetails, [name]: value };

      // Update localStorage whenever the user changes the value
      localStorage.setItem(name, value);

      return { customerDetails: updatedDetails };
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const selectedFlight = JSON.parse(localStorage.getItem('selectedFlight'));
    const flightSearchData = JSON.parse(localStorage.getItem('flightSearchData'));
    const { customerDetails } = this.state;

    // Combine data
    const bookingData = {
      flightSearchData,
      flightDetails: selectedFlight,
      customerDetails,
    };

    console.log('Final Booking Data:', bookingData);

    // Send data to backend
    try {
      await axios.post('https://api.uktravelroute.co.uk/send-booking-email', bookingData);
     // alert('Booking details submitted successfully.');
      localStorage.removeItem('selectedFlight'); // Clean up localStorage
      this.setState({bookingSuccess:true})
      setTimeout(() => {
        this.props.navigate('/')
      },2000)
      
      
    } catch (error) {
      console.error('Error sending booking data:', error);
      //alert('Error sending booking details.');
    }
  };

  render() {
    const { customerDetails } = this.state;

    return (
<div className="formbold-main-wrapper">

<div className="formbold-form-wrapper">
  
  
{!this.state.bookingSuccess && 


  <form onSubmit={this.handleSubmit}>
    <div className="formbold-form-title">
      <h2 className="">Customer Information</h2>
    
    </div>

    <div className="formbold-mb-3">
      <div>
        <label for="firstname" className="formbold-form-label">
          Full name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="formbold-form-input"
          value={customerDetails.name}
          onChange={this.handleChange}
          required
        />
      </div>
      
    </div>

    <div className="formbold-input-flex">
      <div>
        <label for="email" className="formbold-form-label"> Email </label>
        <input
          type="email"
          name="email"
          id="email"
          className="formbold-form-input"
          value={customerDetails.email}
          onChange={this.handleChange}
          required
        />
      </div>
      <div>
        <label for="phone" className="formbold-form-label"> Phone number </label>
        <input
          type="text"
          name="phone"
          id="phone"
          className="formbold-form-input"
          value={customerDetails.phone}
          onChange={this.handleChange}
          required
        />
      </div>
    </div>

    {/* <div className="formbold-mb-3">
      <label for="address" className="formbold-form-label">
        Full Address
      </label>
      <input
        type="text"
        name="address"
        id="address"
        className="formbold-form-input"
      />
    </div>

    

    <div className="formbold-input-flex">
      <div>
        <label for="state" className="formbold-form-label"> State/Prvince </label>
        <input
          type="text"
          name="state"
          id="state"
          className="formbold-form-input"
        />
      </div>
      <div>
        <label for="country" className="formbold-form-label"> Country </label>
        <input
          type="text"
          name="country"
          id="country"
          className="formbold-form-input"
        />
      </div>
    </div> */}

<button className="formbold-btn submit-booking" type="submit">Submit Booking</button>

  </form>
  }

  {this.state.bookingSuccess && 
<p>
Booking done successfully. One of our Travel agent will assist you shortly.
</p>
}
</div>
</div>
    );
  }
}

export default withNavigate(CustomerDetailsForm);
